import {Route, Routes, useNavigate} from "react-router-dom";
import {Home} from "../Home";
import Sidebar from "../../Components/Sidebar";

export const Dashbord = () => {

  const navigate=useNavigate();
  return (
    <>
      <div className="app">
        <Sidebar />
        <Routes>
          <Route path="/" element={<Home/>}/>
        </Routes>
      
      </div>
    </>
  );
};
