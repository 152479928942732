import React, { useContext,useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { updateDocument } from "../../../Middleware/FirebaseFunctions";
import { ordersCollections } from "../../../Config/Collections";
import { AuthContext } from "../../../Config/Context";

export default function CollectPopup({ open, setOpen, order }) {
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [isDisabled, setDisabled] = useState(true);
  const { setLoading } = useContext(AuthContext);

  

  const checkValidate = !amount;
  const handleClose = () => {
    setOpen(false);
  };
  const handelCollect = async () => {
        setLoading(true)
    await updateDocument(ordersCollections, order.id, {
      
      collectAmount: amount,
      note: note,
    });
    setLoading(false)
    setOpen(false)
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle style={{ color: "#EB0028" }}>Collect</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <DialogContentText>
            Please enter the collect amount and a note with it.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            disabled={order.collectAmount ? true : false}
            label={order.collectAmount?order.collectAmount : "Collected Amount"}
            variant="standard"
            type={"number"}
            onChange={(x) => setAmount(x.target.value)}
          />
          <TextField
            margin="dense"
            id="name"
            disabled={order.collectAmount ? true : false}
            label={order.collectAmount?order.note : "Note"}
            fullWidth
            variant="standard"
            multiline
            onChange={(x) => setNote(x.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={checkValidate}
            onClick={handelCollect}
            color="error"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
