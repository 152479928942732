import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import dayjs from "dayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ExportCSV } from "../../Helper/ExportCsv";

export default function ExportPopup({ openExport, setOpenExport, onExport }) {
  const [from, setFrom] = useState(dayjs(new Date()));
  const [to, setTo] = useState("");
  const [isDisabled, setDisabled] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const checkValidate = !to;
  const handleClose = () => {
    setOpenExport(false);
  };  const handelClick = async () => {
    setLoading(true);
    let data = await onExport(from, new Date(dayjs(to).add(1,"day")));
    ExportCSV([
     ...data
    ]);
    setLoading(false);
    handleClose()
  };

  return (
    <div>
      <Dialog open={openExport}>
        <DialogTitle style={{ color: "#1D6F42" }}>Export CSV</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}

          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            
            
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <DialogContentText style={{ margin: "10px 10px" }}>
            Select Date.
          </DialogContentText>
          <span>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="From Date"
                value={!from ? dayjs(new Date()).format("YYYY-MM-DD") : from}
                onChange={(from) => {
                  setFrom(new Date(from));
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </span>
          <span style={{ margin: "0px 10px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="To Date"
                value={to}
                onChange={(to) => {
                  setTo(to);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </span>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isLoading}
            startIcon={<SaveIcon />}
            variant="outlined"
            disabled={checkValidate}
            onClick={() => handelClick()}
            color="info"
          >
            Export
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
