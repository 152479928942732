import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import CollectPopup from "./CollectPopup";
import { Order_status_componet } from "./Order_status_componet";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import dayjs from "dayjs";
import { Tookan_status } from "../../../Assets/DataSchema/Tookan_status";
import {CurrencyIqFormat} from "../../../Components/Format/Currency";
export const Datagrid = ({ orders }) => {
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState(null);
  return (
    <div className="table_container" style={{ overflow: "auto" }}>
      <div className="thead">
        <span className="small_width">#</span>
        <span>Order Id</span>
        <span>User Name</span>
        <span>User Phone</span>
        <span>Create At</span>
        <span>Agent Received Date</span>
        <span>Complete At</span>
        <span>Agent Received Amount</span>
        {/* <span>Collect Amount</span> */}
        <span>Status</span>
        <span>Comment</span>
      </div>

      {orders?.map((e, index) => (
        <div className="body_container" key={index}>
          <span  className="small_width">{index + 1}</span>
          <span>{e.id}</span>
          <span>{e.name}</span>
          <span>{e.phone}</span>
          <span>
            {e.createAt &&
              dayjs(e.createAt.seconds * 1000).format("YYYY-MM-DD")}
          </span>
          <span>
            {e.tokan && dayjs(e.tokan.created_at).format("YYYY-MM-DD hh:mm")}
          </span>
          <span>
            {
              [2,3].includes(e.tokan?.status) ? dayjs(e.tokan.completed_at).format("YYYY-MM-DD hh:mm")
              : " "
            }
         
          </span>
          <span><CurrencyIqFormat val={e.tokan?.partially_completed_amount ||0}/></span>
          {/* <span>{e.collectAmount || 0}</span> */}
          <span>
            <Order_status_componet
              className={`${Tookan_status[e.tokan?.status] || "Processing"}`}
              text={Tookan_status[e.tokan?.status] || "Processing"}
            />
          </span>
          <span></span>
          {/* <span
            onClick={() => {
              setOrder(e);
              setOpen(true);
            }}
          >
            <IconButton aria-label="delete">
              {e.collectAmount > 0 ? (
                <CreditScoreIcon color="success" />
              ) : (
                <CreditCardIcon color="error" />
              )}
            </IconButton>{" "}
          </span> */}
        </div>
      ))}
      {open && <CollectPopup open={open} setOpen={setOpen} order={order} />}
    </div>
  );
};
