import React from "react";

export default function Button(props) {
  return (
    <div>
      <button className={props.className} onClick={props.onclick} disabled={props.disabled}>
        {props.text}
      </button>
    </div>
  );
}
