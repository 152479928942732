import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, MenuItem, Stack } from "@mui/material";
import { Close } from "@mui/icons-material";
import { setDocumnet } from "../../../Middleware/FirebaseFunctions";
import { ordersCollections } from "../../../Config/Collections";
import { AuthContext } from "../../../Config/Context";
import Autocomplete from "react-google-autocomplete";
import { usePlacesWidget } from "react-google-autocomplete";
import { GoolgePlaceAutoComplete } from "../../../Components/GooglePlaceAutoComplete";
import { GetLatLong } from "../../../Components/Map/GetLatLong";
import { Order_status } from "../../../Assets/DataSchema/Order_status";
import { serverTimestamp } from "firebase/firestore";

export const CreateTask = ({ open, setOpen }) => {
  const { currentUser, setLoading } = useContext(AuthContext);
  const [customerName, setCustomerName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [barcode, setBarcode] = useState("");
  const [amount, setAmount] = useState("0");
  const [note, setNote] = useState("");

  const checkValidate =
    !customerName || !phoneNumber || !city || !address || !barcode;
  const HandelCreateTask = async () => {
    setLoading(true)
    let { lat, long } = await GetLatLong(address);
    let data = await setDocumnet(ordersCollections, barcode, {
      city: city || "",
      amount: amount || 0,
      vendor: currentUser.name || "",
      vendorId: currentUser.vendorId || "",
      phone: phoneNumber || "",
      name: customerName || phoneNumber || "",
      lat: lat?.toString() || "",
      long: long?.toString() || "",
      image: "",
      location: address || "",
      entry_status: true,
      note: note || "",
      takeBy: "",
      createBy: currentUser?.name || "",
      status: Order_status.pending,
      job_description: `-`,
      createAt: serverTimestamp(),
      searchTerm: barcode.split(/[-_]/),
    });
    setOpen(false);
    setLoading(false)
  };

  return (
    <div>
      <Dialog
        style={{
          margin: "auto",
        }}
        sx={{
          width: {
            laptop: "50vw",
            tablet: "70vw",
            mobile: "100vw",
          },
        }}
        fullScreen={false}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle style={{ color: "#EB0028" }}>Create Task</DialogTitle>
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={() => setOpen(false)}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <TextField
            required={true}
            margin="dense"
            placeholder="Kasm Karhoot"
            id="customer_name"
            label="Customer Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={(x) => setCustomerName(x.target.value)}
          />

          <TextField
            required={true}
            placeholder="07709092158"
            margin="dense"
            id="phoneNumber"
            label="Phone"
            type="text"
            fullWidth
            variant="standard"
            onChange={(x) => {
              setPhoneNumber(x.target.value);
            }}
          />

          <TextField
            margin="dense"
            id="province"
            label="Province"
            defaultValue={city}
            value={city}
            select
            fullWidth
            variant="standard"
            onChange={(x) => setCity(x.target.value)}
          >
            {["Baghdad", "Anbar"].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            placeholder="Al-yarmouk"
            multiline
            id="address"
            label="Address"
            type="text"
            fullWidth
            variant="standard"
            onChange={(x) => setAddress(x.target.value)}
          />
          <TextField
            placeholder="AWODMIPN-WALKDMLK-LKAWDMLKM"
            margin="dense"
            id="barcode"
            label="Barcode"
            type="text"
            fullWidth
            variant="standard"
            onChange={(x) => setBarcode(x.target.value)}
          />
          <TextField
            placeholder="200000"
            margin="dense"
            id="amount"
            label="Amount"
            type="number"
            fullWidth
            variant="standard"
            helperText="Including Delivery fee"
            onChange={(x) => setAmount(x.target.value)}
          />
          <TextField
            placeholder="Ex: Please, send delivery after 6 PM"
            margin="dense"
            id="note"
            label="Note"
            type="text"
            fullWidth
            variant="standard"
            multiline
            onChange={(x) => setNote(x.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={checkValidate}
            color="error"
            onClick={HandelCreateTask}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
