import { Routes, Route } from "react-router-dom";
import { Spinner } from "./Components/Spinner";
import { Toast } from "./Components/Toast";
import { AuthProvider } from "./Config/Context";
import { Dashbord } from "./Pages/Dashbord";
import Login from "./Pages/Login";

function App() {
  return (
    <AuthProvider>
      <Toast />
      <Spinner />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/*" element={<Dashbord />} />
      </Routes>
    </AuthProvider>
  );
}
//upload
export default App;
