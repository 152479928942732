export const FetchById = (data, method = "POST") => {
  return fetch("https://api.tamata.com/v2/get_job_details_by_order_id", {
    method,
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      order_ids:[...data],
      api_key: process.env.REACT_APP_API_TOOKAN,
      include_task_history: 1,
    }),
  })
    .then((response) => response.json())
    .then((responseData) => {
      if (responseData.status != 200) return {};
            return responseData.data.reduce(
        (a, b) => ({
          ...a,
          [b.barcode]: {
            partially_completed_amount: b.partially_completed_amount,
            driver_comment: b.driver_comment,
            status: b.job_status,
            created_at: b.creation_datetime,
            completed_at: b.completed_datetime,
          },
        }),
        {}
      );
      // return [
      //   ...responseData.data.map((e) => ({
      // partially_completed_amount: e.partially_completed_amount,
      // barcode: e.barcode,

      // driver_comment:e.task_history.find(e=>e.description=="Failed at")?.reason||"",
      // status:e.job_status,
      // created_at: e.creation_datetime,
      // completed_at:e.completed_datetime

      //   })),
      // ];
    })
    .catch((error) => {
      return;
    });
};
