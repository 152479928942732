import { useCallback, useContext, useEffect, useState } from "react";
import Button from "../../Components/Button";
import { Datagrid } from "./Componets/Datagrid";
import { CreateTask } from "./Componets/CreateTask";
import SearchInput from "../../Components/SearchInput";
import { limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { ordersCollections } from "../../Config/Collections";
import { AuthContext } from "../../Config/Context";
import { FetchById } from "../../Middleware/Tookan";
import dayjs from "dayjs";
import { Tookan_status } from "../../Assets/DataSchema/Tookan_status";
import ExportPopup from "../../Components/Excel/ExportPopup";
import {
  getDocArrayContains,
  getDocumnet,
} from "../../Middleware/FirebaseFunctions";
export const Home = () => {
  const [openExport, setOpenExport] = useState(false);
  const [orders, setOrders] = useState([]);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const { currentUser } = useContext(AuthContext);
  const fetchOrders = useCallback(async () => {
    const q = query(
      ordersCollections,
      where("vendorId", "==", currentUser.vendorId),
      orderBy("createAt", "asc"),
      limit(100)
    );
    const unsubscriber = onSnapshot(q, async (snapshot) => {
      var myData = [];
      snapshot.docs.map((doc) => {
        myData.push({ ...doc.data(), id: doc.id });
      });
      let tookanData = await FetchById( myData.map((e) => `T-${e.vendor}${e.id}`),
    );
      myData = myData.map((e) => ({ ...e, tokan: tookanData[e.id] || null }));
      setOrders([...myData]);
    });
    return unsubscriber;
  });
  useEffect(() => {
    fetchOrders();
  }, []);

  const onExport = async (from, to) => {
    var data = await getDocArrayContains(
      ordersCollections,
      "vendorId",
      "==",
      currentUser.vendorId,
      new Date(from),
      new Date(to)
    );

    let tookanData = await FetchById( data.map((e) => `T-${e.vendor}${e.id}`));
    data = data
      .map((e) => ({ ...e, tokan: tookanData[e.id] || null }))
      .map((e, index) => ({
        No: index + 1,
        order_id: e.id,
        user_name: e.name,
        phone: e.phone,
        created_at: dayjs(e.createAt.seconds * 1000).format("YYYY-MM-DD hh:mm"),
        agent_received: e.tokan
          ? dayjs(e.tokan.created_at).format("YYYY-MM-DD hh:mm")
          : "",
        completed_at:[2,3].includes(e.tokan?.status)
          ? dayjs(e.tokan.completed_at).format("YYYY-MM-DD hh:mm")
          : " ",
        city: e.city,
        location: e.location,
        collect_amount:e.collectAmount||0,
        agent_received_amount: e.tokan?.partially_completed_amount||0,
        note: e.note,
        driver_comment: e.tokan?.driver_comment || "",
        stauts: Tookan_status[e.tokan?.status] || "Processing",
      }));
    return data;
  };

  return (
    <>
      <div className="page" style={{ padding: "0 20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "250px",
                height: "60px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <img src="/Images/Earthlink_Logo.png" alt="image_logo" />
            </div>
            <SearchInput
              placeholder={"Search..."}
              onchange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </div>
          <div
            style={{
              // border: "1px solid red",
              marginRight: "40px",
              justifyContent: "end",
              display: "flex",
              flex: 1,
            }}
          >
            <Button
              disabled={orders.length == 0}
              className={"Export"}
              text={"Export Data"}
              onclick={() => setOpenExport(() => true)}
            />
            {/* <Button
              className={"CreateTask"}
              text={"Create Task"}
              onclick={() => setOpen(true)}
            /> */}
          </div>
        </div>
        <Datagrid
          orders={orders.filter(
            (e) => e.id.includes(search || "") || e.name.includes(search || "")
          )}
        />
      </div>
      {open && <CreateTask open={open} setOpen={setOpen} />}
      {openExport && (
        <ExportPopup
          openExport={openExport}
          setOpenExport={setOpenExport}
          onExport={onExport}
        />
      )}
    </>
  );
};