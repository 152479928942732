import {
    arrayUnion,
    deleteDoc,
    doc,
    documentId,
    endAt,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    setDoc,
    startAt,
    updateDoc,
    where,
  } from "firebase/firestore";
  
  export const getAllDoc = async (collection) => {
    if (!collection) return;
    var data = [];
    try {
      const mydata = await getDocs(collection);
      mydata.docs.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      return data;
    } catch (error) {
      console.log(error);
      return;
    }
  };
  
  export const setDocumnet = async (collection, id, payload) => {
    if (!collection || !id) return;
    try {
      await setDoc(doc(collection, id), {
        ...payload,
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  
  export const updateDocument = async (collection, id, data) => {
    if (!collection || !id) return;
    try {
      await updateDoc(doc(collection, id), {
        ...data,
      });
    return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  export const updateArrayDoc = async (collection, id, field, payload) => {
    if (!collection || !id || !payload) return;
    try {
      await updateDoc(doc(collection, id), {
        [field]: arrayUnion(...payload),
      });
    } catch (error) {
      console.log(error);
      return;
    }
  };
  export const getSingleDoc = async (collection, payload) => {
    if (!collection || !payload) return;
    var docSnap;
    try {
      var docSnap = await getDoc(doc(collection, payload));
      if (docSnap.exists()) return { ...docSnap.data(), id: docSnap.id };
    } catch (error) {
      console.log(error);
      return;
    }
  };
  export const getDocumnet = async (collection, payload) => {
    if (!collection || !payload) return;
    var data = [];
    try {
      const mydata = await getDocs(
        query(collection, where(documentId(), "==", payload))
      );
      mydata.docs.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      return data[0];
    } catch (error) {
      console.log(error);
      return;
    }
  };
  export const getMultiDoc = async (collection, payload, field) => {
    if (!collection) return;
    var data = [];
    try {
      const mydata = await getDocs(
        query(collection, where(field, "==", payload))
      );
      mydata.docs.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      return [...data];
    } catch (error) {
      console.log(error);
      return;
    }
  };
  export const getQustomQuery = async (collection, field,queryType,payload) => {
    if (!collection) return;
    var data = [];
    try {
      const mydata = await getDocs(
        query(collection, where(field, queryType, payload))
      );
      mydata.docs.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      return [...data];
    } catch (error) {
      console.log(error);
      return;
    }
  };
  
  export const LimitPagination = async (collection, payload, limitation) => {
    if (!collection) return;
    var data = [];
    try {
      const q = query(collection, orderBy(payload, "desc"), limit(limitation));
      const mydata = await getDocs(q);
      mydata.docs.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      return data;
    } catch (error) {
      console.log(error);
      return;
    }
  };
  
  export const getDocArrayContains = async (
    collection,
    field,
    queryCond,
    payload,
    startDate,
    endDate
  ) => {
    if (!collection || !queryCond || !field) return;
    var data = [];
  
    try {
  
      const mydata = await getDocs(
          query(collection, where(field, queryCond, payload),orderBy("createAt"), where("createAt",">=",startDate),where("createAt","<=",endDate))
      );
      mydata.docs.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      return data;
    } catch (error) {
      console.log(error);
      return;
    }
  };
  
  export const deleteDocument = async (collection, id) => {
    if (!collection || !id) return;
    try {
      await deleteDoc(doc(collection, id));
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  
  
  export const getQueryDocs = async (
    collection,
    field,
    queryCond,
    payload,
  
  ) => {
    if (!collection || !queryCond || !field) return;
    var data = [];
    try {
      while (payload.length) {
        const batch = payload.splice(0, 10);
        const mydata = await getDocs(
          query(
            collection,
            where(field, queryCond, [...batch])
          )
        );
        mydata.docs.forEach((doc) => {
          data.push({ ...doc.data(), id: doc.id });
        });
      }
      return Promise.all(data).then((content) => content.flat());
    } catch (error) {
      console.log(error);
      return;
    }
  };