import {  useContext, useState } from "react";
import Button from "../Components/LoginButton";
import Input from "../Components/LoginInput";
import "../../src/";
import { auth } from "../Config/FirebaseConfig";
import { usersCollection, vendorCollection } from "../Config/Collections";
import { getSingleDoc } from "../Middleware/FirebaseFunctions";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { AuthContext } from "../Config/Context";

const Login = () => {
  const navigate = useNavigate();
  const { setCurrentUser, setLoading } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handelLogin = async (e) => {
    e.preventDefault();
    if (!password || !email) return;
    setLoading(true);
    auth
      .signInWithEmailAndPassword(email, password)
      .then(async (userCredential) => {
        let user = await getSingleDoc(vendorCollection, userCredential.user.uid);
        if (user && user.role == "vendor") {
          setCurrentUser(user);
          setLoading(false);

          navigate("/");
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Invalid Login");
        setLoading(false);
      });
  };

  return (
    <>
      <div className="login-container">
        <div className="login-left-side">
          <h1 className="login-headding">Login</h1>
          <p className="login-text">
            Start using the app and track your order and cash flow
          </p>
          <div className="login-form" id="login-from">
            <form>
              <Input
                id="login-form-email"
                style="input"
                placeholder="Email"
                type="email"
                value={email}
                onchange={(e) => setEmail(e)}
              ></Input>
              <Input
                id="login-form-password"
                style="input"
                placeholder="Password"
                type="password"
                onchange={(e) => setPassword(e)}
                autocomplete={"on"}
                value={password}
              ></Input>

              <Button
                color="ceb0028"
                style="button"
                id="login-form-submit"
                title="Login"
                onclick={(e) => handelLogin(e)}
              ></Button>
            </form>
          </div>
        </div>
        <div className="logo">
          <img width="200" alt="logo" src="/Images/tamata_line2.svg" />
        </div>
      </div>
    </>
  );
};
export default Login;
