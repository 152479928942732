import React, { useEffect, useState } from "react";
import { getSingleDoc } from "../Middleware/FirebaseFunctions";
import { usersCollection, vendorCollection } from "./Collections";
import { auth } from "./FirebaseConfig";
import { useNavigate } from "react-router";

export const AuthContext = React.createContext();
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        let data = await getSingleDoc(vendorCollection, user.uid);
        setCurrentUser(data);
        setPending(false);
      } else {
        setPending(false);
        setCurrentUser(null);
        navigate("/login");
      }
    });
  }, []);
  if (pending) {
    {
      return (
        <>
          <div className="loading_screen">
            <div>
              <img src="/Images/tamata_loading.gif" width="50%" />
            </div>
          </div>
        </>
      );
    }
  }
  return (
    <AuthContext.Provider
      value={{
        currentUser,
        loading,
        setLoading,
        setCurrentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
